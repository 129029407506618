<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        justify-center
        class="overflow-x-auto"
        wrap
    >

      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="'Invoices'"
                    :api-end-point="apiurl"
                    :search="search"
                    :search2="sessionIdSearch"
                    :sort-order="sortOrder"
                    :table-name="'Invoices'"
                    :isDetail="true"
                    :isExport="true"
                    :isExportTable="true"
                    :isCSV="true"
                    :isImport="true"
                    :export-url="'insurances'"
                    :is-custom-filter="false"
                    @import="showImportCsvModal"
                    :pre-selected-filters="filters.selectedFilters"
                    v-on:refreshData="getInvoiceAmounts"
                    :exportTableUrl="'invoice_export'"
                    :exportBtnText="'Invoice submission export'"

        >
          <template slot="filters">
            <filters :filters="filters.bookingStatuses"></filters>
            <filters :filters="filters.claimsStatuses"></filters>
            <filters :filters="filters.invoiceStatuses"></filters>
            <filters :filters="filters.collectionStatuses"></filters>
            <filters :filters="filters.paymentStatuses"></filters>
            <filters :filters="filters.whoGetPaidFilter"></filters>
            <filters :filters="filters.invoiceSettlementDays"></filters>
            <filters :filters="filters.ccDays"></filters>
            <multi-select-filters :filter-id="'therapist_filter'"
                                  :filters="filters.therapistFilter"
                                  :placeholder="'Select therapist'"
                                  :query-type="'relationship'"
                                  :relation-ship-name="'transaction.payment.latestBooking.therapist'"
                                  :column="'id'"
                                  :operator="'in'"
            ></multi-select-filters>

            <!-- Date Range Filter -->
            <date-range-filter :filter-id="'session_time_filter'"
                               :query-type="'relationship'"
                               :relation-ship-name="'transaction.payment.latestBooking.therapistSlot'"
                               :column="'start_time'"
                               :operator="'relationShipBetween'"
            >
            </date-range-filter>
            <!-- Filter for Payment Date -->
              <date-range-filter :filter-id="'payment_date_filter'"
                               :column="'payment_date'"
                               :operator="'between'"
                               :place-holder="'Select Payments Date range'"
            >
            </date-range-filter>

            <!-- Filter for Payment Date -->
            <date-range-filter :filter-id="'invoice_submitted_date_filter'"
                               :column="'invoice_submitted_timestamp'"
                               :operator="'between'"
                               :place-holder="'Invoice Submitted Date'"
            >
            </date-range-filter>

            <div class="ml-3"></div>
            <multi-select-filters :filter-id="'org_filter'"
                                  :filters="filters.therapyOrganizationFilter"
                                  :placeholder="'3rd party'"
                                  :column="'id'"
                                  :query-type="'relationship'"
                                  :operator="'in'"
                                  :relation-ship-name="'insurance.insuranceCompany'"
            ></multi-select-filters>
          </template>
          <template slot="stats" >
            <div class="display-flex flex-row m-t-20">
                <span class="mt-2 ml-2 blue-500 fs-18 outstanding-amount">
                   Overall amount outstanding: £{{ overallOutstandingAmount }}
                </span>
                <span class="mt-2 m-l-75 blue-500 fs-18 outstanding-amount">
                   Invoice total: £{{ invoiceTotal }}
                </span>
            </div>
          </template>
        </data-table>
      </template>

      <!-- Client Detail -->
      <insurance-client-detail v-if="clientDetailDialog"
                               :visible="clientDetailDialog"
                               :row-data="selectedClaim"
                               v-on:hide="clientDetailDialog=false"
      ></insurance-client-detail>

      <invoice-detail v-if="editClaimDialog"
                        :visible="editClaimDialog"
                        :rowData="selectedClaim"
                        v-on:hide="hideEditClaimModal">
      </invoice-detail>

      <!-- Import CSV -->
      <import-csv v-if="importCsvDialog"
                  :visible="importCsvDialog"
                  :api-url="'insurance'"
                  v-on:hide="hideImportCsvModal"
      >
      </import-csv>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "@/components/common/dataTable/DataTable";
import Filters from "@/components/common/dataTable/filters/Filters";
import InsuranceClientDetail from "@/components/transactions/InsuranceClientDetail";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import ImportCsv from "@/components/main/ImportCsv";
import invoiceDetail from '../components/transactions/invoices/index'

export default {
  name: "Insurance",
  components: {
    Filters,
    DataTable,
    InsuranceClientDetail,
    MultiSelectFilters,
    DateRangeFilter,
    ImportCsv,
    invoiceDetail,
  },
  data() {
    return {
      editClaimDialog: false,
      importCsvDialog: false,
      clientDetailDialog: false,
      apiurl: 'insurances',
      search: {
        placeholder: 'Search by Invoice ID, Client name, Payment reference',
        searchAble: true
      },
      sessionIdSearch: {
        placeholder: 'Search by Session ID',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'invoice_id',
          sortField: 'invoice_id',
          direction: 'desc'
        }
      ],
      selectedClaim: null,
      filters: null,
      overallOutstandingAmount: 0,
      invoiceTotal: 0,
      loaded: false,
      date: '',
    }
  },
  created() {
    this.fetchFilters();
  },
  methods: {
    showEditClaimModal() {
      this.editClaimDialog = true;
    },
    hideEditClaimModal() {
      this.editClaimDialog = false;
    },
    fetchFilters() {
      this.$http.get(this.url + 'filters/insurances').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
    showImportCsvModal() {
      this.importCsvDialog = true;
    },
    hideImportCsvModal() {
      this.importCsvDialog = false;
    },
    getInvoiceAmounts(query) {
      let originalURL = this.url + 'insurance/total/amounts?' + query;
      let alteredURL = this.removeSortFromUrl("sort", originalURL);
      this.$http.get(alteredURL).then(response => {
        this.overallOutstandingAmount = response.data.overallOutstandingAmount;
        this.invoiceTotal = response.data.invoiceTotal;
      }).catch(error => {
        console.log(error);
      });
    },
    removeSortFromUrl(key, sourceURL) {
      let rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    }

  },
  events: {
    'open-details': function (data) {
      this.selectedClaim = data;
      this.showEditClaimModal();
    },
    'import-csv': function() {
      this.importCsvDialog = true;
      this.showImportCsvModal();
    },
    'client-detail': function (data) {
      this.selectedClaim = data;
      this.clientDetailDialog = true;
    },
  },
}
</script>

<style scoped>
.outstanding-amount{
  font-family: metropolisRegular !important;
}

</style>
